import React from "react";
import twitterLogo from "../../../../../images/social-logos/twitter.svg";
import CubeImage from "./CubeImage";

(new Image()).src = twitterLogo;

const TwitterImage = () => {
    return <CubeImage img={twitterLogo} alt="Twitter"/>;
};

export default TwitterImage;
