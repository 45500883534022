import React, {useState} from "react";
import {AppBar, Toolbar, List} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import HideOnScroll from "./HideOnScroll";
import MenuItem from "./MenuItem";
import LogoAndName from "./LogoAndName";

// noinspection JSCheckFunctionSignatures
const useStyles = makeStyles(theme => ({
    appBar: {
        background: ({transparent}) => transparent ? 'transparent' : 'rgba(17, 100, 102, 0.8)',
        boxShadow: 'none',
    },
    toolbar: {
        justifyContent: 'space-between',
        zIndex: 1000,
        padding: theme.spacing(2, 3),
    },
    list: {
        display: 'flex',
    },
}));

function TopBar() {
    const [transparent, setTransparent] = useState(true);

    window.onscroll = () => {
        setTransparent(window.pageYOffset <= 100);
    };

    const classes = useStyles({transparent});
    return (
        <HideOnScroll>
            <AppBar id="app-bar" className={classes.appBar} position="fixed">
                <Toolbar variant="dense" className={classes.toolbar}>
                    <LogoAndName/>
                    <List className={classes.list} disablePadding>
                        <MenuItem text="About Me" to="/about-me"/>
                        <MenuItem text="Experience" to="/experience"/>
                        <MenuItem text="Skills" to="/skills"/>
                    </List>
                </Toolbar>
            </AppBar>
        </HideOnScroll>
    );
}

export default TopBar;
