import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {motion} from "framer-motion";

const useStyles = makeStyles({
    root: {
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});

const CubeIconAnimation = ({children, ...props}) => {
    const variants = {
        hidden: {
            scale: 0,
            rotate: -360,
        },
        visible: {
            scale: 1,
            rotate: 0,
            transition: {
                duration: .4,
            },
        },
    };

    const classes = useStyles(props);
    return <motion.div className={classes.root} initial="hidden" animate="visible" variants={variants}>
        {children}
    </motion.div>;
};

export default CubeIconAnimation;
