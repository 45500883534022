import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {NavLink} from "react-router-dom";
import {LinkAnimation} from "../Animations";

const useStyles = makeStyles(theme => ({
    navLink: {
        fontFamily: 'Fira Sans',
        fontSize: '1.5rem',
        textDecoration: 'none',
        color: ({fixedColor}) => fixedColor ? theme.palette.primary.contrastText : theme.palette.secondary.contrastText,
        [theme.breakpoints.down("xs")]: {
            fontSize: '1rem',
        },
        '&:hover': {
            color: theme.palette.primary.contrastText,
        },
        '&:not(:last-child)': {
            marginRight: theme.spacing(4),
        },
    },
    navLinkActive: {
        color: `${theme.palette.primary.contrastText}!important`,
        fontWeight: 'bold',
    },
    lettersWrapper: {
        lineHeight: '100%',
    },
}));

function MenuItem(props) {
    const {text, to, children, noAnimation, fixedColor, ...otherProps} = props;

    const linkIsActive = window.location.pathname === to;

    const classes = useStyles(props);
    let body;
    if (linkIsActive || noAnimation) {
        body = <div className={classes.lettersWrapper}>{children || text}</div>;
    } else {
        body = <LinkAnimation.Container className={classes.lettersWrapper}>
            <div>
                {Array.from(children || text).map((item, i) => (
                    <LinkAnimation.Letter key={i}>
                        {item}
                    </LinkAnimation.Letter>
                ))}
            </div>
        </LinkAnimation.Container>;
    }

    return (
        <NavLink to={to} exact className={classes.navLink} activeClassName={classes.navLinkActive} {...otherProps}>
            {body}
        </NavLink>
    );
}

export default MenuItem;
