import Button from "@material-ui/core/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useContext} from "react";
import {LinkAnimation} from "../../../Animations";
import makeStyles from "@material-ui/core/styles/makeStyles";
import UpAndDownAnimation from "../../../Animations/UpAndDownAnimation";
import {CubeStateContext} from "../../../../pages/Home";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    button: {
        overflow: 'hidden',
        lineHeight: 'initial',
        justifyContent: 'left',
        transition: "max-width .4s",
        maxWidth: ({alwaysExpanded}) => alwaysExpanded ? 'initial' : 0,
        minWidth: 45,
        '& .MuiButton-startIcon': {
            marginRight: 0,
        },
        '&:hover': {
            maxWidth: '100%',
        },
    },
    text: {
        marginLeft: theme.spacing(1),
        display: "inline-block",
        verticalAlign: "top",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
}));

function DownloadResume(props) {
    const {setCubeState} = useContext(CubeStateContext);
    const handleMouseEnter = () => {
        setCubeState && setCubeState('cv');
    };
    const handleMouseLeave = () => {
        setCubeState && setCubeState();
    };

    const classes = useStyles(props);
    return <LinkAnimation.Container className={classes.root} onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}>
        <Button className={classes.button} color="secondary" variant="contained" target="_blank"
                rel="noreferrer noopener nofollow"
                href={`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_CV_PATH}`}
                startIcon={<UpAndDownAnimation>
                    <FontAwesomeIcon icon="download"/>
                </UpAndDownAnimation>}
        >
            <div className={classes.text}>
                {Array.from("Download Resume").map((item, i) => (
                    <LinkAnimation.Letter key={i}>
                        {item}
                    </LinkAnimation.Letter>
                ))}
            </div>
        </Button>
    </LinkAnimation.Container>;
}

export default DownloadResume;
