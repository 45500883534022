import {motion} from "framer-motion";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
    root: {
        display: 'inline-block',
        borderRadius: '50%',
    }
});

const IconAnimation = React.forwardRef(({children, rotate}, ref) => {
    let animationProps = {
        whileHover: {
            scale: [1, 1.1, 1],
            rotate: [0, 360, 0],
            transition: {
                loop: 'Infinity',
                repeatDelay: 1.5,
            }
        },
    };

    const classes = useStyles({rotate});
    return <motion.div ref={ref} className={classes.root} {...animationProps}>
        {children}
    </motion.div>;
});

export default IconAnimation;
