import React from "react";
import githubLogo from "../../../../../images/social-logos/github.svg";
import CubeImage from "./CubeImage";

(new Image()).src = githubLogo;

const GithubImage = () => {
    return <CubeImage img={githubLogo} alt="GitHub"/>;
};

export default GithubImage;
