import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import wave from "../../../images/wave.svg";
import Grid from "@material-ui/core/Grid";
import {Container} from "@material-ui/core";
import GetInTouch from "./GetInTouch";
import Explore from "./Explore";
import Copyright from "./Copyright";
import FooterTitle from "./FooterTitle";

const useStyles = makeStyles(theme => ({
    footer: {
        position: 'relative',
        background: theme.palette.primary.main,
        marginTop: theme.spacing(8),
        width: '100%',
    },
    wave: {
        transform: 'rotate(180deg)',
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '3rem',
        [theme.breakpoints.down("xs")]: {
            height: '1rem',
        },
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingTop: '3.2rem',
        fontSize: '1.25rem',
        height: '100%',
        [theme.breakpoints.down("xs")]: {
            paddingTop: '1rem',
        },
    },
    panels: {
        height: 'calc(100% - 24px)',
    },
    item: {
        padding: theme.spacing(2),
    },
}));

function Footer() {
    const classes = useStyles();
    return <div className={classes.footer}>
        <img src={wave} className={classes.wave} alt=""/>
        <Container className={classes.wrapper}>
            <Grid container className={classes.panels}>
                <Explore className={classes.item} sm={4} xs={12}/>
                <GetInTouch className={classes.item} sm={4} xs={12}/>
                <Grid item className={classes.item} sm={4} xs={12}>
                    <FooterTitle>Ali's AssisBot</FooterTitle>
                    Coming Soon!
                </Grid>
            </Grid>
            <Copyright/>
        </Container>
    </div>;
}

export default Footer;
