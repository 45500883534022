import React, {useEffect, useRef, useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {transform} from "framer-motion";
import useWindowDimensions from "../../_CustomHooks/useWindowDimensions";
import useMousePosition from "@react-hook/mouse-position";
import Faces from "./Faces";

const getTransform = ({clientX, clientY, xInputRange, yInputRange, outputRange}) => {
    if (clientX || clientY) {
        const x = transform(clientX, xInputRange, outputRange);
        const y = transform(clientY, yInputRange, outputRange);

        return `translateZ(-200px) rotateX(${-y || 0}deg) rotateY(${x || 0}deg)`;
    }

    return "translateZ(-200px)";
};

const useStyles = makeStyles({
    scene: {
        width: 400,
        height: 400,
        perspective: 600,
    },
    cube: {
        width: "100%",
        height: "100%",
        position: "relative",
        transformStyle: "preserve-3d",
        zIndex: 0,
    },
    screenTooSmall: {
        width: 'calc(100vw - 200px)',
    },
});

function Cube({homePageRef}) {
    const [mousePosition, ref] = useMousePosition(0, 0, 60);

    const initializedRef = useRef(false);
    if (!initializedRef.current && homePageRef.current) {
        ref(homePageRef.current);
        initializedRef.current = true;
    }

    const [animation, setAnimation] = useState(true);
    const animationTimout = useRef(0);

    const {isOver, clientX, clientY} = mousePosition;
    const {width, height} = useWindowDimensions();

    useEffect(() => {
        if (isOver) {
            animationTimout.current = setTimeout(() => setAnimation(false), 350);
        } else {
            setAnimation(true);
            clearTimeout(animationTimout.current);
        }
    }, [isOver]);

    const transformProps = {
        xInputRange: [0, width],
        yInputRange: [0, height],
        outputRange: [-100, 100],
        clientX,
        clientY,
    };

    const classes = useStyles({clientX, clientY, animation});
    return <div className={classes.scene}>
        <div ref={ref} className={classes.cube} style={{
            transform: getTransform(transformProps),
            transition: (animation || (!clientX && !clientY)) && 'transform 200ms linear',
        }}>
            <Faces/>
        </div>
    </div>;
}

export default Cube;
