import React from "react";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CubeTextZoomAnimation} from "../../../../Animations";

const useStyles = makeStyles(theme => ({
    title: {
        color: theme.palette.primary.contrastText,
    },
    info: {
        color: 'slategrey',
    },
}));

function TitleForLink({subTitle, children}) {
    const classes = useStyles();
    return <CubeTextZoomAnimation>
        <Typography variant="h3" className={classes.title}>{children}</Typography>
        <Typography variant="body2" className={classes.info} component="div">
            <FontAwesomeIcon icon="info-circle"/> {subTitle || 'Tap the cube to navigate'}
        </Typography>
    </CubeTextZoomAnimation>;
}

export default TitleForLink;
