import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(2),
    },
}));

function FooterTitle({children, align}) {
    const classes = useStyles();
    return <Typography align={align || 'center'} variant="h5" className={classes.root}>{children}</Typography>;
}

export default FooterTitle;
