import React, {createContext, useContext, useReducer, useRef} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Cube from "../components/HomePage/Cube";
import cubeStateReducer from "../reducers/cubeStateReducer";
import BottomPanel from "../components/HomePage/SidePanels/BottomPanel";
import LeftPanel from "../components/HomePage/SidePanels/LeftPanel";
import TopPanel from "../components/HomePage/SidePanels/TopPanel";
import RightPanel from "../components/HomePage/SidePanels/RightPanel";
import {AppGeneralContext} from "../App";
import CubeMobile from "../components/HomePage/Cube/mobile";

const useStyles = makeStyles(theme => ({
    '@global': {
        'body': {
            overscrollBehaviorY: 'none',
            userSelect: 'none',
        },
    },
    root: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: theme.palette.primary.main,
        fontSize: '2rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem',
        },
    },

}));

const CubeStateContext = createContext({});

function Home() {
    const [cubeState, setCubeState] = useReducer(cubeStateReducer, {}, () => ({}));
    const homePageRef = useRef();
    const {isMobile} = useContext(AppGeneralContext);

    const classes = useStyles();
    return <CubeStateContext.Provider value={{cubeState, setCubeState}}>
        <div ref={homePageRef} className={classes.root}>
            {isMobile ? <CubeMobile homePageRef={homePageRef}/>
                : <>
                    <Cube homePageRef={homePageRef}/>
                    <LeftPanel/>
                    <TopPanel/>
                    <RightPanel/>
                </>
            }
            <BottomPanel/>
        </div>
    </CubeStateContext.Provider>;
}

export {CubeStateContext};
export default Home;
