import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useMediaQuery} from "@material-ui/core";
import {useTheme} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(15),
        [theme.breakpoints.up("sm")]: {
            height: '12rem',
            flexDirection: ({reverse}) => reverse && 'row-reverse',
        },
        [theme.breakpoints.down("xs")]: {
            flexDirection: 'column',
            marginBottom: theme.spacing(8),
        },
    },
    description: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '65%',
        [theme.breakpoints.up("sm")]: {
            marginRight: ({reverse}) => !reverse && '1rem',
            marginLeft: ({reverse}) => reverse && '1rem',
        },
        [theme.breakpoints.down("xs")]: {
            width: '100%',
            alignItems: 'center',
        },
    },
    title: {
        marginBottom: theme.spacing(3),
        alignSelf: 'center',
    },
    img: {
        width: '35%',
        height: '100%',
        [theme.breakpoints.down("xs")]: {
            marginBottom: theme.spacing(3),
            width: '75%',
            height: '10rem',
        },
    },
}));

function ParagraphSkeleton(props) {
    const {noTitle} = props;

    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const classes = useStyles({smallScreen, ...props});
    return <div className={classes.wrapper}>
        {smallScreen && <>
            {!noTitle && <Skeleton className={classes.title} width="50%" height="2.5rem"/>}
            <div className={classes.img}>
                <Skeleton variant="rect" height="100%" width="100%"/>
            </div>
        </>}
        <div className={classes.description}>
            {!smallScreen && !noTitle && <Skeleton className={classes.title} width="50%" height="2.5rem"/>}
            <Skeleton width="90%" height="2rem"/>
            <Skeleton width="70%" height="2rem"/>
            <Skeleton width="80%" height="2rem"/>
            <Skeleton width="60%" height="2rem"/>
        </div>
        {!smallScreen && <div className={classes.img}>
            <Skeleton variant="rect" height="100%" width="100%"/>
        </div>}
    </div>;
}

export default ParagraphSkeleton;
