import React from "react";
import facebookLogo from "../../../../../images/social-logos/facebook.svg";
import CubeImage from "./CubeImage";

(new Image()).src = facebookLogo;

const FacebookImage = () => {
    return <CubeImage img={facebookLogo} alt="Facebook"/>;
};

export default FacebookImage;
