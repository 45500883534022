import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {CubeIconAnimation} from "../../../../Animations";

const useStyles = makeStyles({
    img: {
        width: '80%',
        transform: ({rotate}) => `rotate(${rotate || 0}deg)`
    }
});

const CubeImage = ({img, alt, children, rotate}) => {
    const classes = useStyles({rotate});

    return <CubeIconAnimation>
        <img className={classes.img} src={img} alt={alt}/>
        {children}
    </CubeIconAnimation>;
};

export default CubeImage;
