import React from "react";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import SocialIconsList from "./SocialIconsList";
import FooterTitle from "../FooterTitle";
import DownloadResume from "./DownloadResume";

const useStyles = makeStyles(theme => ({
    row: {
        marginBottom: theme.spacing(2),
    },
    downloadResume: {
        justifyContent: 'center',
    }
}));

function GetInTouch(props) {
    const classes = useStyles();
    return <Grid item {...props}>
        <FooterTitle>Get in touch</FooterTitle>
        <div className={classes.row}>
            <SocialIconsList/>
        </div>
        <div className={classes.row}>
            <DownloadResume classes={{root: classes.downloadResume}} alwaysExpanded/>
        </div>
    </Grid>;
}

export default GetInTouch;
