import React, {useEffect, useState} from "react";
import Layout from "../components/Layout";
import axios from "axios";
import {makeStyles} from "@material-ui/core/styles";
import ExperienceItem from "../components/ExperienceItem";

const useStyles = makeStyles(theme => ({
    bodyWrapper: {
        textAlign: 'initial',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));


function Experience({id}) {
    const [experiences, setExperiences] = useState([]);
    const [active, setActive] = useState(false);

    useEffect(() => {
        let isSubscribed = true;
        axios('/experiences?_sort=from:DESC').then((response) => {
            if (isSubscribed) {
                setExperiences(response.data);
            }
        });

        return () => {
            isSubscribed = false;
        };
    }, []);

    const classes = useStyles();
    return (
        <Layout id={id} title="Experience" classes={{bodyWrapper: classes.bodyWrapper}}>
            {experiences.length ? experiences.map((experience, index) =>
                <ExperienceItem key={index} active={active === experience.id} setActive={setActive} {...experience}/>,
            ) : <>
                <ExperienceItem skeleton/>
                <ExperienceItem skeleton/>
                <ExperienceItem skeleton/>
            </>}
        </Layout>
    );
}

export default Experience;
