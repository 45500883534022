import MenuItem from "./MenuItem";
import logo from "../../images/logo.png";
import {LettersAnimation} from "../Animations";
import {Box} from "@material-ui/core";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    logo: {
        width: '3rem',
    },
    title: {
        display: 'inline-flex',
        marginLeft: theme.spacing(1),
        fontFamily: 'Fira Sans',
        letterSpacing: '.1rem',
        textTransform: "uppercase",
        fontSize: '1.5rem',
        [theme.breakpoints.down("xs")]: {
            display: 'none',
        },
    },
}));

function LogoAndName() {
    const classes = useStyles();
    return <MenuItem to="/" fixedColor noAnimation>
        <div className={classes.wrapper}>
            <img src={logo} className={classes.logo} alt="Logo"/>
            <LettersAnimation.Container>
                <Box fontWeight={700} fontFamily={'Montserrat'} className={classes.title}>
                    {Array.from("AliElkhateeb").map((item, i) => (
                        <LettersAnimation.Letter key={i}>
                            {item}
                        </LettersAnimation.Letter>
                    ))}
                </Box>
            </LettersAnimation.Container>
        </div>
    </MenuItem>;
}

export default LogoAndName;
