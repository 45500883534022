import React, {useContext} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MenuItem from "../../TopBar/MenuItem";
import {CubeStateContext} from "../../../pages/Home";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        top: 0,
        padding: theme.spacing(2),
        width: '100%',
        height: '7rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    navLink: {
        height: '2.5rem',
        fontSize: '2.5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '2rem',
            height: '2rem',
        },
    },
}));

function TopPanel() {
    const {setCubeState} = useContext(CubeStateContext);

    const handleMouseEnter = () => {
        setCubeState('about-me');
    };
    const handleMouseLeave = () => {
        setCubeState();
    };

    const classes = useStyles();
    return <div className={classes.root}>
        <MenuItem text="About me" to="/about-me" classes={{navLink: classes.navLink, lettersWrapper: classes.menuItem}}
                  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}/>
    </div>;
}

export default TopPanel;
