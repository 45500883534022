import React, {useRef} from "react";
import MuiImageSlider from "mui-image-slider";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    sliderRoot: {
        width: '100%',
        height: ({index}) => index === 1 && '100%',
        [theme.breakpoints.up("sm")]: {
            width: '45%',
        },
    },
    img: {
        maxHeight: '100%',
        width: 'auto',
        maxWidth: '100%',
        borderRadius: 10,
    },
}));

function Photos(props) {
    const {photos} = props;

    const photosPreloadedRef = useRef(false);
    if (!photosPreloadedRef.current && photos.length) {
        photos.map(photo => new Image().src = `${process.env.REACT_APP_API_URL}${photo.url}`);
        photosPreloadedRef.current = true;
    }
    const classes = useStyles(props);
    const sliderProps = {
        images: photos.map(photo => `${process.env.REACT_APP_API_URL}${photo.url}`),
        autoPlay: true,
        classes: {
            root: classes.sliderRoot,
            img: classes.img,
        },
        arrowsBgHoverColor: 'transparent',
    };

    return <MuiImageSlider {...sliderProps}/>;
}

export default Photos;
