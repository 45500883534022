import {motion} from "framer-motion";
import React from "react";

function Slide({children, left}) {
    let props = {
        transition: {duration: .75},
        animate: {x: 0},
    };

    props.initial = {x: left ? '100vw' : '-100vw'};

    return <motion.div {...props}>
        {children}
    </motion.div>
}

export default Slide;
