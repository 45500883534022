import React from "react";
import {CubeTextZoomAnimation} from "../../../../Animations";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function SendEmail () {
    return <CubeTextZoomAnimation>
        <div><FontAwesomeIcon icon="envelope"/></div>
        Send Ali an Email.
    </CubeTextZoomAnimation>
}

export default SendEmail;
