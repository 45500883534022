import React from "react";
import Typography from "@material-ui/core/Typography";
import Photos from "./Photos";
import ReactMarkdown from "react-markdown";
import {Reveal} from "../Animations";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    paragraphTextWrapper: {
        width: '100%',
        display: ({index}) => index && 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.up("sm")]: {
            width: ({index}) => index !== 0 && '60%',
            paddingLeft: ({index}) => index % 2 ? theme.spacing(3) : 0,
            paddingRight: ({index}) => index !== 0 && index % 2 === 0 ? theme.spacing(3) : 0,
        },
    },
    title: {
        fontWeight: 'bold',
        color: theme.palette.primary.contrastText,
        [theme.breakpoints.down("xs")]: {
            marginBottom: theme.spacing(3),
        },
    },
    description: {
        textAlign: 'center',
        fontFamily: 'Montserrat',
        '& p': {
            marginBottom: 0,
        },
        [theme.breakpoints.up("sm")]: {
            textAlign: 'left',
        },
    },
}));

function Description(props) {
    const {smallScreen, title, description, photos, index} = props;
    const classes = useStyles(props);
    return <Reveal className={classes.paragraphTextWrapper}>
        {
            title && <Typography className={classes.title} variant="h4" align="left" component="div">
                {title}
            </Typography>
        }
        {!!index && smallScreen && <Photos index={index} photos={photos}/>}
        <Typography className={classes.description} variant="h5" component="div">
            <ReactMarkdown>
                {description}
            </ReactMarkdown>
        </Typography>
    </Reveal>;
}

export default Description;
