import React from "react";
import SphereEdge from "./SphereEdge";

function Spheres() {
    return <>
        <SphereEdge top right/>
        <SphereEdge top left/>
        <SphereEdge bottom right/>
        <SphereEdge bottom left/>
    </>;
}

export default Spheres;
