import React from "react";
import {useMediaQuery} from "@material-ui/core";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Portrait from "./Portrait";
import clsx from "clsx";
import Photos from "./Photos";
import Description from "./Description";

const useStyles = makeStyles(theme => ({
    paragraphWrapper: {
        display: 'inline-block',
        '&:not(:first-child)': {
            display: 'flex',
            alignItems: 'center',
        },
        '&:not(:last-child)': {
            marginBottom: theme.spacing(15),
            [theme.breakpoints.down("xs")]: {
                marginBottom: theme.spacing(8),
            },
        },
    },
    reversed: {
        flexDirection: 'row-reverse',
    },
}));

function Paragraph(props) {
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const {index} = props;

    const classes = useStyles(props);
    return <div className={clsx(classes.paragraphWrapper, !(index % 2) && classes.reversed)}>
        {
            index === 0 ? <Portrait/> : !smallScreen && <Photos {...props}/>
        }
        <Description smallScreen={smallScreen} {...props}/>
    </div>;
}

export default Paragraph;
