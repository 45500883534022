import React, {useContext} from "react";
import Spheres from "../Spheres";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {CubeTextScaleAnimation} from "../../../Animations";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useHistory} from "react-router-dom";
import {motion} from "framer-motion";
import {isEqual} from "lodash";
import {AppGeneralContext} from "../../../../App";

const useStyles = makeStyles({
    root: {},
    content: {
        perspective: 1,
    },
    iconWrapper: {},
    icon: {},
});

function Left({showIconExperience, animateContent, ...props}) {
    const text = "I am currently working at Zalando as a Software Engineer, owning some projects in fulfillment solutions.";
    const {isMobile} = useContext(AppGeneralContext);

    const history = useHistory();

    const classes = useStyles(props);

    let content;
    if (animateContent) {
        content = <CubeTextScaleAnimation className={classes.content}>
            {text}
        </CubeTextScaleAnimation>;
    } else {
        content = <div className={classes.content}>
            {text}
        </div>;
    }

    const motionProps = {
        initial: {
            x: '-100vh',
            rotateY: -90,
            rotateX: -180,
        },
        animate: {
            x: isMobile ? -150 : -200,
            rotateX: 0,
            z: 0,
        },
        transition: {
            duration: 1,
        },
        onTap: () => {
            showIconExperience && history.push('/experience');
        },
    };

    return <motion.div className={classes.root} {...motionProps}>
        <Spheres/>
        <div className={classes.content}>
            {(showIconExperience) && <div className={classes.iconWrapper}>
                <FontAwesomeIcon className={classes.icon} icon="chevron-right"/>
            </div>}
            {content}
        </div>
    </motion.div>;
}

export default React.memo(Left, (prevProps, nextProps) => isEqual(prevProps, nextProps));
