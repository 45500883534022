import React, {useContext} from "react";
import DownloadResume from "../../Layout/Footer/GetInTouch/DownloadResume";
import SocialIconsList from "../../Layout/Footer/GetInTouch/SocialIconsList";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {AppGeneralContext} from "../../../App";
import Copyright from "../../Layout/Footer/Copyright";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        bottom: 0,
        padding: theme.spacing(2, 2, 0),
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 2,
        flexDirection: 'column',
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    downloadResume: {
        [theme.breakpoints.up('md')]: {
            position: 'absolute',
            left: 0,
            marginLeft: theme.spacing(2.5),
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(1.5),
        },
    },
}));

function BottomPanel() {
    const {isMobile} = useContext(AppGeneralContext);

    const classes = useStyles();
    return <div className={classes.root}>
        <div className={classes.wrapper}>
            {!isMobile && <div className={classes.downloadResume}>
                <DownloadResume/>
            </div>}
            <SocialIconsList noAnimation/>
        </div>
        <Copyright topMargin/>
    </div>;
}

export default BottomPanel;
