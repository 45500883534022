import React from "react";
import {motion} from "framer-motion";

const CubeTextScaleAnimation = ({children, ...props}) => {
    const variants = {
        after: {
            y: [0, 5, 0],
            transition: {
                type: 'spring',
                duration: .4,
                loop: 'Infinity',
                repeatDelay: .2,
            }
        },
    };

    return <motion.div animate="after" variants={variants} {...props}>
        {children}
    </motion.div>;
};

export default CubeTextScaleAnimation;
