import {motion, useAnimation} from "framer-motion";
import React, {useContext, useEffect, useRef} from "react";
import {isElementInViewport} from "./index";
import {AppGeneralContext} from "../../App";

function Reveal({children, ...props}) {
    const controls = useAnimation();
    const {scrollYProgress} = useContext(AppGeneralContext);

    useEffect(() => {
        const unsubscribe = scrollYProgress.onChange(handleScrollChange);

        function handleScrollChange() {
            if (isElementInViewport(elementRef.current)) {
                controls.start("visible");
                unsubscribe();
            }
        }

        handleScrollChange();
        return unsubscribe;
    }, [scrollYProgress, controls]);

    const elementRef = useRef();

    const item = {
        visible: {opacity: 1, y: 0},
        hidden: {opacity: 0, y: 100},
    };

    return <motion.div ref={elementRef} initial="hidden" variants={item} animate={controls} transition={{duration: .5}} {...props}>
        {children}
    </motion.div>;
}

export default Reveal;
