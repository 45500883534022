import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
    scene: {
        height: "3%",
        width: "3%",
        position: "absolute",
        perspective: 600,
        top: ({top}) => top && 'calc(0px - 1.5%)',
        right: ({right}) => right && 'calc(0px - 1.5%)',
        bottom: ({bottom}) => bottom && 'calc(0px - 1.5%)',
        left: ({left}) => left && 'calc(0px - 1.5%)',
    },
    sphere: {
        height: '100%',
        transformStyle: "preserve-3d",
    },
    hemisphere: {
        height: '100%',
    },
    face: {
        background: 'white',
        width: '100%',
        height: '100%',
        position: 'absolute',
        borderRadius: '50%',
        "&:nth-child(2)": {
            transform: 'rotateX(45deg)',
        },
        "&:nth-child(3)": {
            transform: 'rotateX(90deg)',
        },
        "&:nth-child(4)": {
            transform: 'rotateX(135deg)',
        },
        "&:nth-child(5)": {
            transform: 'rotateX(180deg)',
        },
        "&:nth-child(6)": {
            transform: 'rotateX(225deg)',
        },
        "&:nth-child(7)": {
            transform: 'rotateX(270deg)',
        },
        "&:nth-child(8)": {
            transform: 'rotateX(215deg)',
        },
    },
});

function SphereEdge(props) {
    const classes = useStyles(props);
    return <div className={classes.scene}>
        <div className={classes.sphere}>
            <div className={classes.hemisphere}>
                <div className={classes.face}/>
            </div>
        </div>
    </div>;
}

export default SphereEdge;
