import React, {useContext} from "react";
import Front from "./Front";
import Back from "./Back";
import Top from "./Top";
import Right from "./Right";
import Left from "./Left";
import Bottom from "./Bottom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {CubeStateContext} from "../../../../pages/Home";

const useStyles = makeStyles(theme => ({
    cube__face: {
        position: "absolute",
        width: 400,
        height: 400,
        background: `${theme.palette.primary.main}cc`,
        border: "2px solid white",
        [theme.breakpoints.down('sm')]: {
            width: 300,
            height: 300,
        },
    },
    cube__face__content: {
        display: "flex",
        flexDirection: 'column',
        justifyContent: "center",
        alignItems: "center",
        height: '100%',
        width: '100%',
        padding: theme.spacing(2),
    },
    cube__face__icon_wrapper: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        padding: theme.spacing(0, 1),
        borderLeft: '2px solid white',
        borderTop: '2px solid white',
        borderTopLeftRadius: '20%',
    },
    cube__face__icon: {},
}));

function Faces() {
    // TODO: move this to each face with the useful attributes only
    const {cubeState} = useContext(CubeStateContext);
    const {topContent, rightAnimateContent, bottomAnimateContent, leftAnimateContent, showIconDownloadCV, showIconAboutMe, showIconGetInTouch, showIconSkills, showIconExperience} = cubeState;

    const classes = useStyles();
    return <>
        <Front
            classes={{root: classes.cube__face, content: classes.cube__face__content}}/>
        <Back
            classes={{root: classes.cube__face, content: classes.cube__face__content}}/>
        <Top classes={{root: classes.cube__face, content: classes.cube__face__content, icon: classes.cube__face__icon}}
             showIconDownloadCV={showIconDownloadCV} showIconGetInTouch={showIconGetInTouch}>
            {topContent}
        </Top>
        <Right
            classes={{
                root: classes.cube__face,
                content: classes.cube__face__content,
                iconWrapper: classes.cube__face__icon_wrapper,
                icon: classes.cube__face__icon,
            }}
            animateContent={rightAnimateContent} showIconSkills={showIconSkills}/>
        <Left classes={{
            root: classes.cube__face,
            content: classes.cube__face__content,
            iconWrapper: classes.cube__face__icon_wrapper,
            icon: classes.cube__face__icon,
        }}
              animateContent={leftAnimateContent} showIconExperience={showIconExperience}/>
        <Bottom
            classes={{
                root: classes.cube__face,
                content: classes.cube__face__content,
                iconWrapper: classes.cube__face__icon_wrapper,
                icon: classes.cube__face__icon,
            }}
            animateContent={bottomAnimateContent} showIconAboutMe={showIconAboutMe}/>
    </>;
}

export default Faces;
