import * as LettersAnimation from "./LettersAnimation";
import * as LinkAnimation from "./LinkAnimation";
import IconAnimation from "./IconAnimation";
import Slide from "./Slide";
import Reveal from "./Reveal";
import UpAndDownAnimation from "./UpAndDownAnimation";
import CubeTextScaleAnimation from "./cube/CubeTextScaleAnimation";
import CubeIconAnimation from "./cube/CubeIconAnimation";
import CubeTextZoomAnimation from "./cube/CubeTextZoomAnimation";

function isElementInViewport(el) {
    let rect = el.getBoundingClientRect();

    return (
        rect.right >= 0
        && rect.top <= (window.innerHeight || document.documentElement.clientHeight)
        && rect.left <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

export {
    LinkAnimation,
    IconAnimation,
    LettersAnimation,
    Slide,
    isElementInViewport,
    Reveal,
    UpAndDownAnimation,
    CubeIconAnimation,
    CubeTextScaleAnimation,
    CubeTextZoomAnimation,
};
