import {motion} from "framer-motion";
import React from "react";

const CubeTextZoomAnimation = ({children, className}) => {
    const variants = {
        hidden: {
            scale: 0,
        },
        visible: {
            scale: 1,
        },
    };

    return <motion.div className={className} initial="hidden" animate="visible" variants={variants}>
        {children}
    </motion.div>;
};

export default CubeTextZoomAnimation;
