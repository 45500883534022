import React, {useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useMediaQuery, useTheme} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Faces from "../Faces";
import {motion} from "framer-motion";
import Title from "./Title";

const getTransform = ({rotation}) => {
    let result = "translateZ(-200px)";
    if (rotation.y) {
        result += ` rotateX(${-rotation.y}deg)`;
    }
    if (rotation.x) {
        result += ` rotateY(${rotation.x}deg)`;
    }

    return result;
};

const useStyles = makeStyles(theme => ({
    sceneWrapper: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: theme.spacing(10),
        zIndex: 100,
    },
    scene: {
        width: 300,
        height: 300,
        perspective: 450,
        zIndex: 100,
    },
    cube: {
        width: "100%",
        height: "100%",
        position: "relative",
        transformStyle: "preserve-3d",
        transform: getTransform,
    },
    screenTooSmall: {
        width: 'calc(100vw - 200px)',
    },
}));

function CubeMobile({homePageRef}) {
    const [rotation, setRotation] = useState({x: 0, y: 0});

    const theme = useTheme();
    const isTooSmallScreen = useMediaQuery(theme.breakpoints.down(350));

    const classes = useStyles({rotation});

    const onDrag = (event, info) => {
        setRotation(prevRotation => ({x: prevRotation.x + info.delta.x, y: prevRotation.y + info.delta.y}));
    };

    return isTooSmallScreen ?
        <Typography className={classes.screenTooSmall}>The cube is too big for the screen</Typography>
        : <motion.div className={classes.sceneWrapper} ref={homePageRef} drag onDrag={onDrag}
                      dragConstraints={{left: 0, top: 0, right: 0, bottom: 0}}
                      dragElastic={0}
                      dragMomentum={false}
        >
            <Title {...rotation}/>
            <div className={classes.scene}>
                <div className={classes.cube}>
                    <Faces/>
                </div>
            </div>
        </motion.div>;
}

export default CubeMobile;
