import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import {motion} from "framer-motion";
import Header from "./Header";
import Footer from "./Footer";
import TopBar from "../TopBar";

const useStyles = makeStyles(theme => ({
    container: {
        marginBottom: theme.spacing(8),
        marginTop: theme.spacing(8),
    },
    bodyWrapper: {
        marginTop: theme.spacing(4),
    },
}));

function Layout(props) {
    const {title, children} = props;

    const classes = useStyles(props);
    return (
        <>
            <TopBar/>
            <Header title={title}/>
            <Container className={classes.container}>
                <motion.div animate={{opacity: 1}} transition={{duration: 1}} initial={{opacity: 0}}>
                    <div className={classes.bodyWrapper}>
                        {children}
                    </div>
                </motion.div>
            </Container>
            <Footer/>
        </>
    );
}

export default Layout;
