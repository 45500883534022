import React, {useContext} from "react";
import Spheres from "../Spheres";
import logo from "../../../../images/logo-white.png";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {isEqual} from "lodash";
import {motion} from "framer-motion";
import {AppGeneralContext} from "../../../../App";

const useStyles = makeStyles({
    root: {},
    content: {},
    logo: {
        opacity: .5,
    },
});

function Back(props) {
    const {isMobile} = useContext(AppGeneralContext);

    const classes = useStyles(props);

    const motionProps = {
        initial: {
            scale: 0,
        },
        animate: {
            scale: 1,
            z: isMobile ? -150 : -200,
        },
        transition: {
            duration: 1,
        },
    };

    return <motion.div className={classes.root} {...motionProps}>
        <Spheres/>
        <div className={classes.content}>
            <img src={logo} className={classes.logo} alt='logo'/>
        </div>
    </motion.div>;
}

export default React.memo(Back, (prevProps, nextProps) => isEqual(prevProps, nextProps));
