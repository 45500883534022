import {makeStyles, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import moment from "moment";
import ReactMarkdown from "react-markdown";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles(theme => ({
    imgLogo: {
        [theme.breakpoints.down('xs')]: {
            width: '3rem',
        },
        width: '4rem',
        verticalAlign: 'middle',
        marginRight: theme.spacing(1),
    },
    titleWrapper: {
        width: '100%',
        marginLeft: theme.spacing(1.5),
    },
    duration: {
        width: '50%',
        color: "grey",
        marginRight: theme.spacing(2),
    },
    description: {
        fontSize: '1.25rem',
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
            paddingLeft: 0,
            borderLeft: 'none',
        },
        marginLeft: "2rem",
        '& a': {
            color: 'orange',
        },
    },
    titleRoot: {
        '&:hover': {
            borderTopLeftRadius: 'inherit',
            borderTopRightRadius: 'inherit',
            background: '#585858',
        },
    },
    titleContent: {
        margin: theme.spacing(2.5, 0),
        display: "flex",
        alignItems: "center",
        position: 'relative',
    },
    titleFocused: {
        backgroundColor: 'initial !important',
    },
}));

const LinkRenderer = (props) =>
    <a href={props.href} onClick={(e) => e.stopPropagation()} target="_blank" rel="nofollow noopener noreferrer">
        {props.children}
    </a>;

function ExperienceItem({id, company, from, to, description, logo, position, type, active, setActive, skeleton}) {
    const classes = useStyles();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const imgUrl = !skeleton && `${process.env.REACT_APP_API_URL}${logo.url}`;
    const momentFrom = !skeleton && moment(from);
    const momentTo = !skeleton && moment(to);

    const ChevronIcon = <FontAwesomeIcon className={classes.chevron} icon="chevron-down"/>;

    const Duration = !skeleton && <Typography variant="h5" className={classes.duration} display="inline">
        ({momentFrom.format('MMM Y')} - {momentTo.isValid() ? momentTo.format('MMM Y') : 'Present'})
    </Typography>;

    const handleChange = (event, isActive) => !skeleton && setActive(isActive ? id : false);

    return <ExpansionPanel className={classes.root} expanded={!skeleton && active} onChange={handleChange}>
        <ExpansionPanelSummary expandIcon={ChevronIcon}
                               classes={{
                                   root: classes.titleRoot,
                                   content: classes.titleContent,
                                   focused: classes.titleFocused,
                               }}>
            {
                skeleton ? <Skeleton variant="rect" width="4rem" height="3rem"/>
                    : <img src={imgUrl} className={classes.imgLogo} alt={company}/>
            }
            <div className={classes.titleWrapper}>
                <Typography variant="h4">
                    {company || <Skeleton width="40%"/>}
                </Typography>
                <Typography>
                    {position || <Skeleton width="20%"/>} {type === 'parttime' && '(Part Time)'}
                </Typography>
                {mobile && (Duration || <Skeleton width="30%" style={{marginRight: theme.spacing(2)}}/>)}
            </div>
            {!mobile && (Duration || <Skeleton width="30%" style={{marginRight: theme.spacing(2)}}/>)}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.description}>
            <ReactMarkdown renderers={{link: LinkRenderer}}>
                {description}
            </ReactMarkdown>
        </ExpansionPanelDetails>
    </ExpansionPanel>;
}

export default ExperienceItem;
