import {motion} from "framer-motion";
import React from "react";

const Container = ({children, hover, ...props}) => {
    const containerVariants = {
        before: {},
        after: {
            transition: {
                staggerChildren: .1,
            },
        },
    };

    let animationProps = {
        initial: 'before',
        variants: containerVariants,
    };

    animationProps[hover ? 'whileHover' : 'animate'] = 'after';

    return <motion.div {...animationProps} {...props}>
        {children}
    </motion.div>;

};

const Letter = ({children, duration}) => {
    const letterVariants = {
        before: {
            opacity: 0,
            x: 40,
            transition: {
                duration: duration || .2,
            },
        },
        after: {
            opacity: 1,
            x: 0,
            transition: {
                duration: duration || .2,
            },
        },
    };

    return <motion.span variants={letterVariants}>
        {children}
    </motion.span>;
};

export {Container, Letter};
