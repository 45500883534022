import React from "react";
import SocialIcon from "./SocialIcon";

function SocialIconsList(props) {
    return <div>
        <SocialIcon {...props} title="linkedin" icon={['fab', 'linkedin-in']}
                    url="https://www.linkedin.com/in/ali-elkhateeb/"/>
        <SocialIcon {...props} title="facebook" icon={['fab', 'facebook-f']}
                    url="https://www.facebook.com/ali.a.elkhateeb"/>
        <SocialIcon {...props} title="twitter" icon={['fab', 'twitter']}
                    url="https://twitter.com/AliElKhateeb"/>
        <SocialIcon {...props} title="github" icon={['fab', 'github']}
                    url="https://github.com/alielkhateeb"/>
        <SocialIcon {...props} title="stack-overflow" icon={['fab', 'stack-overflow']}
                    url="https://stackoverflow.com/users/7021694/ali-elkhateeb"/>
        <SocialIcon {...props} title="email" icon='envelope'
                    url="mailto:ali.elkhateeb1990@gmail.com"/>
    </div>;
}

export default SocialIconsList;
