import React from "react";
import linkedinLogo from "../../../../../images/social-logos/linkedin.svg";
import CubeImage from "./CubeImage";

(new Image()).src = linkedinLogo;

const LinkedInImage = () => {
    return <CubeImage img={linkedinLogo} alt="LinkedIn"/>;
};

export default LinkedInImage;
