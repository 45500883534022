import {motion} from "framer-motion";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
    root: {
        display: 'inline-block',
    },
});

const Container = ({children, animate, ...props}) => {
    const containerVariants = {
        before: {},
        after: {
            transition: {
                staggerChildren: .03,
            },
        },
    };

    let animationProps = {
        initial: 'before',
        variants: containerVariants,
        whileHover: 'after',
    };

    return <motion.div {...animationProps} {...props}>
        {children}
    </motion.div>;

};

const Letter = ({children}) => {
    const letterVariants = {
        before: {
            y: 0,
        },
        after: {
            y: [0, -5, 0],
            transition: {
                duration: .15,
                loop: 'Infinity',
                repeatDelay: 1,
                stiffness: 200,
            }
        },
    };

    const classes = useStyles();
    return <motion.div className={classes.root} variants={letterVariants}>
        {children === " " ? "\u00A0" : children}
    </motion.div>;
};

export {Container, Letter};
