import React from "react";
import Particles from "react-particles-js";
import particlesConfig from "../../config/particlesjs-config";
import wave from "../../images/wave.svg";
import {Typography, Divider} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Slide} from "../Animations";

const useStyles = makeStyles(theme => ({
    header: {
        position: 'relative',
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        overflowX: 'hidden',
    },
    particlesWrapper: {
        width: '100%',
        position: 'relative',
    },
    particles: {
        background: theme.palette.primary.main,
    },
    wave: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
        height: '3rem',
        [theme.breakpoints.down("xs")]: {
            height: '1rem',
        },
    },
    title: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: '#ee9a22',
        position: 'absolute',
        top: 'calc(50% - 3rem)',
    },
    divider: {
        height: '.25rem',
        background: '#ee9a22',
        marginTop: theme.spacing(2),
        marginLeft: '25%',
        marginRight: '25%',
    },
}));

function Header({title}) {
    const classes = useStyles();
    return <div className={classes.header}>
        <div className={classes.particlesWrapper}>
            <Particles height={400} params={particlesConfig} className={classes.particles}/>
            <img src={wave} className={classes.wave} alt=""/>
        </div>
        <Typography className={classes.title} variant="h2" paragraph component="div">
            <Slide>
                {title}
            </Slide>
            <Slide left>
                <Divider className={classes.divider} variant="middle" component="div"/>
            </Slide>
        </Typography>
    </div>;
}

export default Header;
