import React, {useContext} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MenuItem from "../../TopBar/MenuItem";
import {CubeStateContext} from "../../../pages/Home";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        left: 0,
        top: 0,
        padding: theme.spacing(2),
        height: '100%',
        width: '7rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-start',
        },
    },
    menuItem: {
        transform: 'rotate(-90deg)',
        whiteSpace: 'nowrap',
    },
    navLink: {
        width: '2.5rem',
        fontSize: '2.5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '2rem',
            height: '2rem',
        },
    },
}));

function LeftPanel() {
    const {setCubeState} = useContext(CubeStateContext);

    const handleMouseEnter = () => {
        setCubeState('skills');
    };
    const handleMouseLeave = () => {
        setCubeState();
    };

    const classes = useStyles();
    return <div className={classes.root}>
        <MenuItem text="Skills" to="/skills" classes={{navLink: classes.navLink, lettersWrapper: classes.menuItem}}
                  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}/>
    </div>;
}

export default LeftPanel;
