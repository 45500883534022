import makeStyles from "@material-ui/core/styles/makeStyles";
import IconButton from "@material-ui/core/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useContext} from "react";
import {IconAnimation} from "../../../Animations";
import {CubeStateContext} from "../../../../pages/Home";

const useStyles = makeStyles(theme => ({
    icon: {
        width: theme.spacing(6),
        background: theme.palette.secondary.main,
        margin: theme.spacing(0, .25),
        '&:hover': {
            background: theme.palette.secondary.main,
        },
    },
}));

const SocialIcon = ({title, icon, url, noAnimation}) => {
    const {setCubeState} = useContext(CubeStateContext);
    const handleMouseEnter = () => {
        setCubeState && setCubeState(title);
    };
    const handleMouseLeave = () => {
        setCubeState && setCubeState();
    };

    const classes = useStyles();
    const IconElement = <IconButton className={classes.icon} disableRipple disableFocusRipple href={url} target="_blank"
                                    rel="nofollow noopener noreferrer" onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}>
        <FontAwesomeIcon icon={icon}/>
    </IconButton>;

    return noAnimation ? IconElement : <IconAnimation>
        {IconElement}
    </IconAnimation>
};

export default SocialIcon;
