import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import portraitImage from "../../images/portrait.png";

(new Image()).src = portraitImage;

const useStyles = makeStyles(theme => ({
    portraitWrapper: {
        position: 'relative',
        [theme.breakpoints.up("sm")]: {
            shapeOutside: 'polygon(100% -20%, 70% -22%, 64% -68%, 37% -68%, 33% 2%, 16% 10%, 9% 22%, 3% 56%, 9% 58%, 9% 71%, 9% 76%, 6% 99%, 99% 99%)',
            float: 'right',
            width: '35%',
            marginTop: '-20%',
        },
    },
    portrait: {
        width: '100%',
        [theme.breakpoints.down("xs")]: {
            marginBottom: theme.spacing(2),
        },
    },
}));

function Portrait () {
    const classes = useStyles();
    return <div className={classes.portraitWrapper}>
        <img src={portraitImage} className={classes.portrait} alt="Ali's Portrait"/>
    </div>
}

export default Portrait;
