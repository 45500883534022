import React from "react";
import SendEmail from "../components/HomePage/Cube/Faces/Hovers/SendEmail";
import FacebookImage from "../components/HomePage/Cube/Faces/Hovers/FacebookImage";
import TwitterImage from "../components/HomePage/Cube/Faces/Hovers/TwitterImage";
import GithubImage from "../components/HomePage/Cube/Faces/Hovers/GithubImage";
import StackOverflowImage from "../components/HomePage/Cube/Faces/Hovers/StackOverflowImage";
import LinkedInImage from "../components/HomePage/Cube/Faces/Hovers/LinkedInImage";
import CVImage from "../components/HomePage/Cube/Faces/Hovers/CVImage";

const cubeStateReducer = (content, hovered) => {
    switch (hovered) {
        case 'linkedin':
            return {topContent: <LinkedInImage/>};
        case 'facebook':
            return {topContent: <FacebookImage/>};
        case 'twitter':
            return {topContent: <TwitterImage/>};
        case 'stack-overflow':
            return {topContent: <StackOverflowImage/>};
        case 'github':
            return {topContent: <GithubImage/>};
        case 'email':
            return {topContent: <SendEmail/>};
        case 'cv':
            return {topContent: <CVImage/>, showIconDownloadCV: true};
        case 'skills':
            return {rightAnimateContent: true, showIconSkills: true};
        case 'about-me':
            return {bottomAnimateContent: true, showIconAboutMe: true};
        case 'experience':
            return {leftAnimateContent: true, showIconExperience: true};
        case 'showIcon About Me':
            return {showIconAboutMe: true};
        case 'showIcon Skills':
            return {showIconSkills: true};
        case 'showIcon Experience':
            return {showIconExperience: true};
        case 'showIcon Resume':
            return {showIconDownloadCV: true};
        default:
            return {};
    }
};

export default cubeStateReducer;
