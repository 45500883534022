import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {
    faUser,
    faBars,
    faBriefcase,
    faThumbtack,
    faChevronUp,
    faCaretDown,
    faChevronDown,
    faCopyright,
    faEnvelope,
    faDownload,
    faSyncAlt, faCube, faExternalLinkAlt, faChevronRight, faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';

// Add icons you want to use in the project.
library.add(
    fab,
    faUser,
    faBars,
    faBriefcase,
    faThumbtack,
    faChevronUp,
    faChevronDown,
    faCaretDown,
    faCopyright,
    faEnvelope,
    faDownload,
    faSyncAlt,
    faCube,
    faExternalLinkAlt,
    faChevronRight,
    faInfoCircle,
);

ReactDOM.render(<App/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
