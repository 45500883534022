import React from "react";
import Grid from "@material-ui/core/Grid";
import MenuItem from "../../TopBar/MenuItem";
import {List, ListItem} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FooterTitle from "./FooterTitle";

const useStyles = makeStyles(theme => ({
    list: {
        "& .MuiListItem-root": {
            paddingTop: 0,
            paddingBottom: theme.spacing(1.5),
            [theme.breakpoints.down('xs')]: {
                justifyContent: 'center',
                width: '50%',
                display: 'inline-flex',
            },
        },
    },
}));

function Explore(props) {
    const classes = useStyles();
    return <Grid item {...props}>
        <FooterTitle>Explore</FooterTitle>
        <List className={classes.list}>
            <ListItem>
                <MenuItem text="Home" to="/"/>
            </ListItem>
            <ListItem>
                <MenuItem text="About Me" to="/about-me"/>
            </ListItem>
            <ListItem>
                <MenuItem text="Experience" to="/experience"/>
            </ListItem>
            <ListItem>
                <MenuItem text="Skills" to="/skills"/>
            </ListItem>
        </List>
    </Grid>;
}

export default Explore;
