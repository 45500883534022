import React from "react";
import CVSnapshot from "../../../../../images/cv-snapshot.svg";
import CubeImage from "./CubeImage";

(new Image()).src = CVSnapshot;

const CVImage = (props) => {
    return <CubeImage img={CVSnapshot} alt="CV" {...props}/>;
};

export default CVImage;
