import React from 'react';
import {createMuiTheme, CssBaseline, Container, responsiveFontSizes, useMediaQuery} from "@material-ui/core";
import {makeStyles, MuiThemeProvider} from "@material-ui/core/styles/index";
import AboutMe from "./pages/AboutMe";
import Experience from "./pages/Experience";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import axios from "axios";
import Skills from "./pages/Skills";
import Home from "./pages/Home";
import {useViewportScroll} from "framer-motion";
import defaultTheme from "./defaultTheme";

let theme = createMuiTheme(defaultTheme);

theme = responsiveFontSizes(theme);

const useStyles = makeStyles({
    root: {
        display: 'flex',
        textAlign: 'center',
        fontFamily: 'Montserrat',
    },
    content: {
        padding: 0,
        maxWidth: "initial",
    },
    divider: {
        maxWidth: '50%',
        margin: 'auto',
    },
});

const AppGeneralContext = React.createContext({});

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

function App() {
    const {scrollYProgress} = useViewportScroll();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {noSsr: true});

    const classes = useStyles();
    return (
        <MuiThemeProvider theme={theme}>
            <AppGeneralContext.Provider value={{scrollYProgress, isMobile}}>
                <CssBaseline/>
                <div id="root-element" className={classes.root}>
                    <Container className={classes.content}>
                        <Router>
                            <Switch>
                                <Route exact path="/">
                                    <Home id="home-page"/>
                                </Route>
                                <Route exact path="/about-me">
                                    <AboutMe id="about-me"/>
                                </Route>
                                <Route exact path="/experience">
                                    <Experience id="experience"/>
                                </Route>
                                <Route exact path="/skills">
                                    <Skills id="skills"/>
                                </Route>
                            </Switch>
                        </Router>
                    </Container>
                </div>
            </AppGeneralContext.Provider>
        </MuiThemeProvider>
    );
}

export default App;
export {AppGeneralContext};
