import {motion} from "framer-motion";
import React from "react";

const UpAndDownAnimation = React.forwardRef(({children, ...props}, ref) => {
    const iconVariants = {
        before: {
            y: 0,
        },
        after: {
            y: [2.5, 0, -2.5, 0],
            transition: {
                type: 'spring',
                duration: .5,
                loop: 'Infinity',
                repeatDelay: 1,
            }
        },
    };

    return <motion.div ref={ref} variants={iconVariants} {...props}>
        {children}
    </motion.div>;
});

export default UpAndDownAnimation;
