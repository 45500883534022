export default {
    palette: {
        type: "dark",
        primary: {main: '#116466', active: '#188F92', contrastText: '#EE9A22'},
        secondary: {main: '#2C3531', contrastText: '#D1E8E2'},
    },
    typography: {
        fontFamily: 'Montserrat',
        h1: {fontFamily: 'Fira Sans'},
        h2: {fontFamily: 'Fira Sans'},
        h3: {fontFamily: 'Fira Sans'},
        h4: {fontFamily: 'Fira Sans'},
        h5: {fontFamily: 'Fira Sans'},
        h6: {fontFamily: 'Fira Sans'},
        subtitle1: {fontFamily: 'Fira Sans'},
        subtitle2: {fontFamily: 'Fira Sans'},
    },
};
