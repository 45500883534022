import Typography from "@material-ui/core/Typography";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    root: {
        fontWeight: 300,
        color: 'darkgrey',
        marginBottom: theme.spacing(.5),
        marginTop: ({topMargin}) => topMargin && theme.spacing(1.5),
    }
}));

function Copyright(props) {
    const classes = useStyles(props);
    return <div className={classes.root}>
        <Typography variant="body2">
            Copyright <FontAwesomeIcon icon="copyright"/> {moment().year()}, Ali Elkhateeb. All rights reserved.
        </Typography>
    </div>
}

export default Copyright;
