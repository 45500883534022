import React from "react";
import {LettersAnimation} from "./Animations";
import {Card, Typography} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Skeleton from "@material-ui/lab/Skeleton";
import Link from "@material-ui/core/Link";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const useStyles = makeStyles(theme => ({
    card: {
        position: 'relative',
        width: 180,
        height: 120,
        [theme.breakpoints.down('xs')]: {
            width: 120,
            height: 70,
        },
        '&:hover': {
            '& $externalLinkIcon': {
                display: 'block',
            }
        }
    },
    link: {
        color: 'white',
    },
    externalLinkIcon: {
        position: 'absolute',
        bottom: 2,
        right: 2,
        zIndex: 1,
        display: 'none',
    },
    mediaWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        '& img': {
            maxWidth: '80%',
            height: '80%',
        },
    },
    titleWrapper: {
        position: 'absolute',
        top: 0,
        cursor: 'pointer',
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            backgroundColor: '#000000c4',
        },
    },
    title: {
        display: 'inline-flex',
        userSelect: 'none',
        flexWrap: 'wrap',
        whiteSpace: 'pre-wrap',
        justifyContent: 'center',
    },
}));

function SkillItem({skill, skeleton}) {
    const classes = useStyles();
    return <Card className={classes.card}>
        {skeleton ? <Skeleton variant="rect" width="100%" height="100%"/>
            : <Link className={classes.link} target="_blank" rel="nofollow noopener noreferrer" href={skill.url}>
                <div className={classes.externalLinkIcon}>
                    <FontAwesomeIcon icon="external-link-alt"/>
                </div>
                <div className={classes.mediaWrapper}>
                    <img src={`${process.env.REACT_APP_API_URL}${skill.logo.url}`} alt=""/>
                </div>
                <LettersAnimation.Container hover className={classes.titleWrapper} duration={.1}>
                    <Typography className={classes.title} gutterBottom variant="h5" component="div">
                        {Array.from(skill.name).map((item, i) => (
                            <LettersAnimation.Letter key={i}>
                                {item}
                            </LettersAnimation.Letter>
                        ))}
                    </Typography>
                </LettersAnimation.Container>
            </Link>}
    </Card>;
}

export default SkillItem;
