import React, {useContext} from "react";
import Spheres from "../Spheres";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {CubeTextZoomAnimation} from "../../../Animations";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {isEqual} from "lodash";
import {motion} from "framer-motion";
import {AppGeneralContext} from "../../../../App";
import CVImage from "./Hovers/CVImage";

const useStyles = makeStyles(theme => ({
    root: {},
    content: {
        perspective: 1,
    },
    icon: {
        position: 'absolute',
        top: ({isMobile}) => !isMobile && theme.spacing(.5),
        bottom: ({isMobile}) => isMobile && theme.spacing(.5),
        right: ({isMobile}) => theme.spacing(.5),
    },
}));

function Top({children, showIconDownloadCV, showIconGetInTouch, ...props}) {
    const {isMobile} = useContext(AppGeneralContext);

    const classes = useStyles({isMobile, ...props});

    const motionProps = {
        initial: {
            y: '-100vh',
            rotateX: 90,
        },
        animate: {
            y: isMobile ? -150 : -200,
            z: 0,
        },
        transition: {
            duration: 1,
        },
        onTap: () => {
            showIconDownloadCV && window.open(
                `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_CV_PATH}`,
                "_blank", "noopener noreferrer nofollow"
            );
        },
    };

    const iconProps = {
        icon: 'download',
    };
    if (!isMobile) {
        iconProps.rotation = 270;
    }

    return <motion.div className={classes.root} {...motionProps}>
        <Spheres/>
        <div className={classes.content}>
            {showIconDownloadCV && <FontAwesomeIcon className={classes.icon} {...iconProps}/>}
            {children || <CubeTextZoomAnimation>
                {isMobile ? <CVImage rotate={90}/> : 'Get in touch'}
            </CubeTextZoomAnimation>}
        </div>
    </motion.div>;
}

export default React.memo(Top, (prevProps, nextProps) => isEqual(prevProps, nextProps));
