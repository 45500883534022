import React from "react";
import {useScrollTrigger, Slide} from "@material-ui/core";

function HideOnScroll(props) {
    const {children} = props;

    const trigger = useScrollTrigger();

    return (
        <Slide direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

export default HideOnScroll;
