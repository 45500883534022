import React, {useContext, useEffect, useState} from "react";
import DefaultTitle from "./DefaultTitle";
import {CubeStateContext} from "../../../../../pages/Home";
import TitleForLink from "./TitleForLink";

const threshold = 30;
const isWithinAngle = (currentAngle, withinAngle) => {
    return Math.abs(withinAngle - currentAngle) < threshold
        || Math.abs(withinAngle + 360 - currentAngle) < threshold
        || Math.abs(withinAngle - 360 - currentAngle) < threshold;
};

function Title({x, y}) {
    const {setCubeState} = useContext(CubeStateContext);
    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');

    useEffect(() => {
        const angleX = x % 360;
        const angleY = y % 360;
        let newTitle = '';
        let newSubTitle = '';

        if (isWithinAngle(angleX, 90) && isWithinAngle(angleY, 0)) {
            newTitle = 'Experience';
        } else if (isWithinAngle(angleX, -90) && isWithinAngle(angleY, 0)) {
            newTitle = 'Skills';
        } else if (isWithinAngle(angleX, 0) && isWithinAngle(angleY, -90)) {
            newTitle = 'About Me';
        } else if (isWithinAngle(angleX, 0) && isWithinAngle(angleY, 90)) {
            newTitle = 'Resume';
            newSubTitle = 'Tap the cube to download';
        }

        setTitle(newTitle);
        setSubTitle(newSubTitle);
        setCubeState(newTitle && `showIcon ${newTitle}`);
    }, [x, y, setCubeState]);

    return title ? <TitleForLink subTitle={subTitle}>{title}</TitleForLink> : <DefaultTitle/>;
}

export default Title;
