import React, {useEffect, useState} from "react";
import Layout from "../components/Layout";
import {makeStyles} from "@material-ui/core/styles";
import {TextField} from "@material-ui/core";
import axios from "axios";
import {includes} from "lodash";
import SkillItem from "../components/SkillItem";

const useStyles = makeStyles(theme => ({
    cardsContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, 180px)',
        gridGap: theme.spacing(2.5),
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            gridGap: theme.spacing(2),
        },
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: 'repeat(auto-fill, 120px)',
            gridGap: theme.spacing(1),
        },
    },
    search: {
        width: '100%',
        padding: theme.spacing(2.5),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
        },
        '& .MuiInputBase-root': {
            borderRadius: 20,
        },
    },
}));

function Skills({id}) {
    const [skills, setSkills] = useState([]);
    const [displayedSkills, setDisplayedSkills] = useState([]);

    useEffect(() => {
        let isSubscribed = true;
        axios('/skills?_sort=strength:DESC').then((response) => {
            if (isSubscribed) {
                setSkills(response.data);
                setDisplayedSkills(response.data);
            }
        });

        return () => {
            isSubscribed = false;
        };
    }, []);

    const onChange = (event) => {
        let skillsToFind = event.target.value.trim();
        if (!skillsToFind) {
            return setDisplayedSkills(skills);
        }
        skillsToFind = event.target.value.toLowerCase().split(',');
        setDisplayedSkills(skills.filter(skill => {
            for (let skillToFind of skillsToFind) {
                skillToFind = skillToFind.trim();
                if (skillToFind && includes(skill.name.toLowerCase(), skillToFind)) {
                    return true;
                }
            }
            return false;
        }));
    };

    const classes = useStyles();
    return (
        <Layout id={id} title="Skills">
            <div className={classes.search}>
                <TextField variant="outlined" fullWidth label="Search" placeholder="Comma Separated items..."
                           onChange={onChange}/>
            </div>
            <div className={classes.cardsContainer}>
                {skills.length ? displayedSkills.map((skill, index) => <SkillItem key={index} skill={skill}/>)
                    : <>
                        <SkillItem skeleton/>
                        <SkillItem skeleton/>
                        <SkillItem skeleton/>
                        <SkillItem skeleton/>
                        <SkillItem skeleton/>
                        <SkillItem skeleton/>
                        <SkillItem skeleton/>
                        <SkillItem skeleton/>
                        <SkillItem skeleton/>
                        <SkillItem skeleton/>
                    </>}
            </div>
        </Layout>
    );
}

export default Skills;
