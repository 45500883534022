import React from "react";
import stackOverflowLogo from "../../../../../images/social-logos/stackoverflow.svg";
import CubeImage from "./CubeImage";

(new Image()).src = stackOverflowLogo;

const StackOverflowImage = () => {
    return <CubeImage img={stackOverflowLogo} alt="StackOverflow"/>;
};

export default StackOverflowImage;
