import React, {useContext} from "react";
import Spheres from "../Spheres";
import {Box} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {isEqual} from "lodash";
import {AppGeneralContext} from "../../../../App";
import {motion} from "framer-motion";

const useStyles = makeStyles(theme => ({
    root: {
        transform: "translateZ(200px)",
        [theme.breakpoints.down('sm')]: {
            transform: "translateZ(150px)",
        },
    },
    content: {},
}));

function Front(props) {
    const {isMobile} = useContext(AppGeneralContext);
    const motionProps = {
        initial: {
            scale: 3,
            opacity: 0,
            rotateZ: -180,
        },
        animate: {
            scale: 1,
            opacity: 1,
            rotateZ: 0,
            z: isMobile ? 150 : 200,
        },
        transition: {
            duration: 1,
        },
    };

    const classes = useStyles(props);
    return <motion.div className={classes.root} {...motionProps}>
        <Spheres/>
        <div className={classes.content}>
            <Box variant="h4">Hey there!</Box>
            <Box variant="h4">
                My name is Ali, I'm a Full-Stack Engineer from Egypt, currently based in Berlin, Germany.
            </Box>
        </div>
    </motion.div>;
}

export default React.memo(Front, (prevProps, nextProps) => isEqual(prevProps, nextProps));
