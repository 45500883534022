import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Layout from "../components/Layout";
import axios from "axios";
import Paragraph from "../components/Paragraph";
import ParagraphSkeleton from "../components/Paragraph/ParagraphSkeleton";

const useStyles = makeStyles(theme => ({
    bodyWrapper: {
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(8),
            marginRight: theme.spacing(8),
        },
        [theme.breakpoints.down("xs")]: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: '-50%',
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
    },
}));

function AboutMe({id}) {
    const [paragraphs, setParagraphs] = useState([]);

    useEffect(() => {
        let isSubscribed = true;
        axios('/paragraphs').then((response) => {
            if (isSubscribed) {
                setParagraphs(response.data)
            }
        });

        return () => {
            isSubscribed = false;
        };
    }, []);

    const classes = useStyles();
    return (
        <Layout id={id} title="About Me" classes={{bodyWrapper: paragraphs.length && classes.bodyWrapper}}>
            <div>
                {
                    paragraphs.length ? paragraphs.map((paragraph, index) =>
                        <Paragraph key={index} index={index} {...paragraph}/>,
                    ) : <>
                        <ParagraphSkeleton noTitle/>
                        <ParagraphSkeleton reverse/>
                        <ParagraphSkeleton/>
                    </>
                }
            </div>
        </Layout>
    );
}

export default AboutMe;
