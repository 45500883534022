import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import rotateCube from "../../../../../images/rotate-cube.svg";
import Typography from "@material-ui/core/Typography";
import {CubeTextZoomAnimation} from "../../../../Animations";

const useStyles = makeStyles({
    root: {
        height: 50,
    },
    img: {
        maxHeight: 40,
    },
});

function DefaultTitle() {
    const classes = useStyles();
    return <CubeTextZoomAnimation className={classes.root}>
        <Typography variant="h3" component="div">
            <img className={classes.img} src={rotateCube} alt="Rotate Cube"/>
        </Typography>
    </CubeTextZoomAnimation>;
}

export default DefaultTitle;
